import Vue from 'vue'
import VueRouter from 'vue-router'

import NotFound from '../components/common/NotFound.vue';
Vue.use(VueRouter)
const routes = [

  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home/index.vue'),
    meta: {
    }
  },
  {
    path: '/dataset',
    name: 'dataset',
    component: () => import('../views/Dataset/index.vue'),
    meta: {
    }
  },

  {
    path: '/dataset-detail',
    name: 'datasetDetail',
    component: () => import('../views/Dataset/detail.vue'),
    meta: {
    }
  },

  {
    path: '/model',
    name: 'model',
    component: () => import('../views/Model/index.vue'),
    meta: {
    }
  },
  {
    path: '/model-detail',
    name: 'modelDetail',
    component: () => import('../views/Model/detail.vue'),
    meta: {
    }
  },
 
  {
    path: '/leaderboard/:path',
    name: 'leaderboard',
    component: () => import('../views/Leaderboard/index.vue'),
    meta: {
    }
  },
  {
    path: '/leaderboard',
    name: 'leaderboard',
    component: () => import('../views/Leaderboard/index.vue'),
    meta: {
    }
  },

  {
    path: '/CTCMB',
    name: 'CTCMB',
    component: () => import('../views/CMB/index.vue'),
    meta: {
    }
  },
  {
    path: '/FIN',
    name: 'FIN',
    component: () => import('../views/FIN/index.vue'),
    meta: {
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFound
  }
]


const router = new VueRouter({
  routes,

})
// 添加一个全局的 afterEach 导航守卫
router.afterEach(() => {
  // 每次路由切换时，将页面滚动到顶部
  window.scrollTo(0, 0);
});
export default router
